import React from 'react'

export const ErrorPage = () => {
  return (
   <>
   <div className='text-slate-400'>
    <h1 className='font-bold text-3xl flex justify-center py-5'>Oops page not Found!</h1>
   </div>
   </>
  )
}
